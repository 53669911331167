<template>
  <div class="bg-[#e7e2e2] border m-0 rounded-xl p-8 w-full mb-4 md:mb-0 flex flex-col justify-between" style="background-color: rgba(231, 226, 226, 0.3)">
    <div class="flex justify-between items-center">
      <h4 class="text-[12px] font-semibold uppercase">
        {{ subscription?.name || 'Standard Pro Plan' }}
      </h4>
      <img src="@/assets/icons/more2.svg" class="mr-2 cursor-pointer" alt="" v-if="!showFeatures" />
    </div>
    <div class="mt-5 mb-5">
      <h1 class="text-[24px] font-semibold font-manrope">
        {{ rate == 1 ? currencyCodes.usd : currencyCodes.ngn }} {{ formatNumber(subscription?.amount * rate) || '0'
        }}<small class="text-[15px] font-medium text-[#000000]/[.6]">/month</small>
      </h1>
    </div>
    <div v-if="showFeatures">
      <feature v-for="(item, index) in features" :key="index" :title="item" />
    </div>
    <div class="flex justify-end mt-10" v-if="showCta">
      <TamButton :to="`/subscription-plan/${subscription?.uuid}`" class="ml-3 md:ml-0 w-full" color="black" label="View more" />
    </div>
  </div>
</template>

<script setup>
import Feature from '@/components/subscriptions/Feature.vue';
import TamButton from '@/components/TamButton.vue';
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import { formatNumber } from '@/utils/helper';

const emit = defineEmits(['updateStatus', 'deletePlan']);
const router = useRouter();

const props = defineProps({
  subscription: { type: Object, default: () => ({}) },
  showFeatures: { type: Boolean, default: false },
  showCta: { type: Boolean, default: false },
  rate: { type: Number, default: 1 },
});

const currencyCodes = ref({
  usd: '$',
  ngn: '₦',
});

const features = computed(() => {
  return props.subscription.features;
});
</script>
